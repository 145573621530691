import { Box } from '@mui/material';
import { COLOR_WHITE } from '../../constants/color';

interface ComponentContainerProps {
    children: React.ReactElement | React.ReactElement[];
    styleProps?: {};
}

export default function ComponentContainer({ children, styleProps }: ComponentContainerProps) {
    return (
        <Box
            maxWidth="lg"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                p: { xs: '0px 20px', md: '0px 20px' },
                m: '0px auto',
                ...styleProps,
                backgroundColor: 'transparent'
            }}>
            {children}
        </Box>
    );
}
