// Define API endpoint paths.
import { DOMAIN } from './config';

//Authentication
export const getLoginURL = () => `${DOMAIN}/Users/Login`;
export const getSignUpURL = () => `${DOMAIN}/Users/SignUp`;
export const getConfirmEmailURL = () => `${DOMAIN}/Users/ConfirmEmail`;
export const getResendCodeURL = () => `${DOMAIN}/Users/ResendCode`;
export const postUserActivateURL = () => `${DOMAIN}/Users/activate`;

//Change Password
export const getChangePasswordURL = () => `${DOMAIN}/Users/ChangePassword`;

//Recover Password
export const getForgetPasswordCodeURL = () => `${DOMAIN}/Users/ForgetPasswordCode`;
export const getVerifyForgetPasswordCodeURL = () => `${DOMAIN}/Users/VerifyForgetPasswordCode`;
export const getResendForgetPasswordCodeURL = () => `${DOMAIN}/Users/ResendForgetPasswordCode`;
export const getChangePasswordTokenURL = () => `${DOMAIN}/Users/ChangePasswordToken`;

//Scholarships
export const getScholarshipStatsURL = () => `${DOMAIN}/Students/scholarships/stats`;
export const getScholarshipFeaturedURL = () => `${DOMAIN}/Scholarships/Featured`;
export const getScholarshipCountAmountURL = () => `${DOMAIN}/Scholarships/ScholarshipCountAmount`;
// export const getScholarshipAppliedURL =() => `${DOMAIN}/Students/scholarships/applied`
export const getScholarshipStats = `${DOMAIN}/Students/scholarships/stats`;
export const getScholarshipAppliedURL = `${DOMAIN}/Students/scholarships/applied`;
export const getScholarshipDeleteURL = () => `${DOMAIN}/students/scholarships/deleted`;
export const getScholarshipSavedURL = `${DOMAIN}/Students/scholarships/saved`;
export const getUnsaveScholarshipURL = (id: number) => `${DOMAIN}/Students/scholarships/unsaved?id=${id}`;
export const getSaveScholarshipURL = (id: number) => `${DOMAIN}/Students/scholarships/Saved?ids=${id}`;
export const getScholarshipAwardedURL = `${DOMAIN}/Students/scholarships/awarded`;
export const getScholarshipMatchedURL = `${DOMAIN}/Students/scholarships/matched`;
export const getScholarshipSuggestedURL = `${DOMAIN}/Students/scholarships/suggested`;
export const getApplyScholarShipURL = (scholarshipId: number) => `${DOMAIN}/Scholarships/${scholarshipId}/Apply`;
export const getFindMoreMatchesURL = `${DOMAIN}/Organizations/scholarships-list`;
export const getAddOrganizationForScholarshipURL = (id: number) => `${DOMAIN}/Students/scholarships-list/Add?id=${id}`;
export const getRemoveOrganizationFromScholarshipListURL = (id: number) =>
    `${DOMAIN}/Students/scholarships-list/Delete?id=${id}`;
export const getNonProfitOrganizationLogoURL = (id: number) => `${DOMAIN}/Organizations/${id}/Logo`;
export const getAppliedScholarshipDetailsURL = (scholarshipId: number) => `${DOMAIN}/Scholarships/${scholarshipId}`;
export const getScholarshipDetailsByScholarshipIdURL = (scholarshipId: number) => `${DOMAIN}/scholarship-by-id/${scholarshipId}`;
export const getChangeScholarshipStatustoAppliedURL = (scholarshipId: number) =>
    `${DOMAIN}/students/scholarships/Applied?ids=${scholarshipId}`;
export const getChangeScholarshipStatusURL = (status: string) => `${DOMAIN}/students/scholarships/${status}`;
export const getScholarshipFilterURL = `${DOMAIN}/students/scholarships/filter`;
export const getScholarshipDetailsURL = (scholarshipId: number) => `${DOMAIN}/Scholarships/${scholarshipId}`;

//colleges
export const getCollegesFindURL = () => `${DOMAIN}/Students/colleges/find`;
export const getCollegesSuggestedURL = () => `${DOMAIN}/Students/colleges/suggested`;
export const getCollegesSavedURL = () => `${DOMAIN}/Students/colleges/saved`;
export const getCollegesApplyingURL = () => `${DOMAIN}/Students/colleges/applying`;
export const getCollegesAcceptedURL = () => `${DOMAIN}/Students/colleges/accepted`;
export const getCollegesStatsURL = (studentId: number) => `${DOMAIN}/Students/colleges/stats?studentId=${studentId}`;
export const getStudentCollegesDeleteURL = (collegeIds: number[]) =>
    `${DOMAIN}/Students/colleges/delete?ids=${collegeIds}`;
//status change
export const getCollegesSaveURL = (status: string, ids: number) => `${DOMAIN}/students/colleges/${status}?ids=${ids}`;
export const getCollegesUnsaveURL = (id: number) => `${DOMAIN}/students/colleges/unsaved?id=${id}`;

//Notifications
export const getNotificationCountURL = () => `${DOMAIN}/MobileNotifications/GetNotificationCount`;
export const getNotificationListURL = () => `${DOMAIN}/MobileNotifications/GetNotifications`;

//MyAccountDetails
export const getMyAccountURL = `${DOMAIN}/Students/detail`;
export const getMyAccountAdminURL = `${DOMAIN}/Admin/detail`;
export const getMyAccountCounselorURL = `${DOMAIN}/Counselors/detail`;
export const getProfilePicURL = `${DOMAIN}/Students/`; //add  student  id  and  /profile_pic
export const getStudentsAboutURL = `${DOMAIN}/Students/about`;
export const getStudentsContactURL = `${DOMAIN}/Students/contact`;
export const getStatesURL = () => `${DOMAIN}/States`;
export const getYearsInSchoolURL = () => `${DOMAIN}/YearsInSchools`;
export const getOrganizationSchoolsURL = () => `${DOMAIN}/Organizations/schools`;
export const getOrganizationCollegesURL = () => `${DOMAIN}/Organizations/colleges`;
export const getEthnicities = () => `${DOMAIN}/Ethnicities`;
export const getGenderId = () => `${DOMAIN}/Genders`;
export const getEducationDetailURL = `${DOMAIN}/Students/education`;
export const getAddSchoolDetailURL = `${DOMAIN}/Students/Organization`;
export const getAddProfilePictureURL = `${DOMAIN}/Users/profile_pic`;
export const getAddUserProfileURL = `${DOMAIN}/Users/profile_pic`;

//User Activity
export const getUserActivityURL = () => `${DOMAIN}/Students/activitylog`;

//Logging Errors
export const getLoggersURL = `${DOMAIN}/Loggers`;

//Sign Up
export const getSignUpUrlAbouYou = `${DOMAIN}/Users/SignUp`;
export const getSignUpVerifyEmail = `${DOMAIN}/Users/ConfirmEmail`;
export const getSignUpResendCode = `${DOMAIN}/Users/ResendCode`;
export const getSignUpEducationDetails = `${DOMAIN}/Students/education`;
export const getSignUpMoreAbout = `${DOMAIN}/Students/about`;

//Apply for Scholarships
export const getApplicaitonAttachmentsURL = (applicationDraftId: number, applicationStatus: string) =>
    `${DOMAIN}/Application/attachments?applicationDraftId=${applicationDraftId}&status=${applicationStatus}`;
export const getApplicaitonAttachmentsUploadedURL = (scholarshipVersionId: number) =>
    `${DOMAIN}/Application/attachments?scholarshipVersionId=${scholarshipVersionId}`;
export const getApplicaitonStatusURL = (status: string, applicationDraftId: number) =>
    `${DOMAIN}/Applications/scholarships/${status}?ids=${applicationDraftId}`;
export const getApplicationAboutURL = `${DOMAIN}/Applications/Students/about`;
export const getApplicationContactEducationURL = `${DOMAIN}/Applications/Students/contact-education`;
export const getApplicationReviewURL = (scholarshipVersionId: number) =>
    `${DOMAIN}/Application/review?scholarshipVersionId=${scholarshipVersionId}`;
export const getApplicationAttachmentDeleteURL = (attachmentId: number) =>
    `${DOMAIN}/Attachments/delete?attachementIds=${attachmentId}`;
export const getApplicationAttachmentDownloadURL = (attachmentId: number) =>
    `${DOMAIN}/Attachments/downloads/${attachmentId}`;
export const getApplicationProcessChangeStatusURL = (attachmentId: number) =>
    `${DOMAIN}/Attachments/downloads/${attachmentId}`;
export const getApplicationSubmitURL = (scholarshipVersionId: number) =>
    `${DOMAIN}/Application/submit?scholarshipVersionId=${scholarshipVersionId}`;
export const getStudentAppliedScholarshipApplicationDetailsURL = (scholarshipVersionId: number, studentId: number) =>
    `${DOMAIN}/Application/application-detail?scholarshipVersionId=${scholarshipVersionId}&studentId=${studentId}`;

// admin dashboard
export const getAdminScholarshipURL = `${DOMAIN}/Scholarships`;
export const getAdminScholarshipFeatureURL = () => `${DOMAIN}/Admin/scholarships/feature`;
export const getAdminScholarshipUnfeatureURL = () => `${DOMAIN}/Admin/scholarships/Unfeature`;
export const getAdminRecentSignUpURL = `${DOMAIN}/Users`;
export const getAdminExternalURL = `${DOMAIN}/admin/scholarships/external-list`;
export const getAdminOrganizationURL = `${DOMAIN}/admin/organization/stat`;
export const getAdminSchoolsURL = `${DOMAIN}/Organizations/schools`;
export const getAdminProvidersURL = `${DOMAIN}/admin/providers`;
export const getAdminNonProfitURL = `${DOMAIN}/admin/non-profits`;
export const getAdminStudentURL = `${DOMAIN}/admin/student/YIS-stat`;
export const getAdminStudentsURL = `${DOMAIN}/admin/students`;
export const getLogoNeededURL = `${DOMAIN}/Organizations/logo-needed-organizations`;
export const getOrganizationCollegeURL = `${DOMAIN}/Organizations/colleges`;
export const getOrganizationsURL = `${DOMAIN}/Organizations`;
export const getAdminDetail = `${DOMAIN}/Admin/detail`;
export const getExternalScholarshipListURL = `${DOMAIN}/ExternalScholarshipList`;
export const getExternalScholarshipListLogoURL = (id: number) => `${DOMAIN}/ExternalScholarshipList/Logo?id=${id}`;
export const getAdminAddSponsorURL = `${DOMAIN}/Admin/Addsponsor`;
export const getSponsorStatsURL = `${DOMAIN}/Admin/sponsor/stat`;
export const getAdminScholarshipDeleteURL = (scholarshipsVersionId: number) =>
    `${DOMAIN}/Scholarships/${scholarshipsVersionId}`;
export const getHighSchoolsSponsoredURL = (sponsorUserId: number) =>
    `${DOMAIN}/Admin/sponsor/organization?sponsorUserId=${sponsorUserId}`;
export const getAdminStudentScholarshipStatsURL = `${DOMAIN}/Students/scholarships/stats`;
export const getAdminStudentDetailsURL = `${DOMAIN}/Students/detail`;
export const getAdminCopyScholarshipsURL = `${DOMAIN}/Admin/copy-scholarships`;
export const getAdminExternalScholarshipListOrganizationURL = `${DOMAIN}/ExternalScholarshipList/Organization`;
export const getEditDeleteScholarshipURL = (scholarshipsVersionId: number) =>
    `${DOMAIN}/Scholarships/${scholarshipsVersionId}`;
//Counselor Dashboard Apis
export const getCounselorsOrganizationsYISstat = `${DOMAIN}/Counselors/Organization/YIS-stat`;
export const getCounselorsOrganizationStudentStat = `${DOMAIN}/Counselors/Organization/Student-stat`;
export const getCounselorsOrganizationActivitiesStat = `${DOMAIN}/Counselors/Organization/Activities-stat`;
export const getCounselorsApplicationManagerURL = `${DOMAIN}/Organizations/applyable-scholarships`;
export const getCounselorsTaskManagerURL = `${DOMAIN}/ChatGPT/GetScholarshipFromUrl`;
export const getCounselorsListManagerScholarshipsURL = `${DOMAIN}/Organizations/scholarships`;
export const getCounselorsListManagerScholarshipDeleteURL = `${DOMAIN}/Organizations/scholarships/delete`;
export const getCounselorsListManagerDefaultOrganizationListLinkedURL = `${DOMAIN}/Counselors/default_organization/linked`;
export const getCounselorsListManagerDefaultOrganizationListUnlinkedURL = `${DOMAIN}/Counselors/default_organization/unlinked`;
export const getChatgptPdfURL = `${DOMAIN}/ChatGPT/GetScholarhipFromFile`;
export const getCounselorAddScholarship = `${DOMAIN}/Counselors/AddScholarship`;
export const getCounselorOrganizationsBrandingURL = `${DOMAIN}/Organizations/Branding`;
export const getCounselorOrganizationsScholarshipsListBooster = `${DOMAIN}/Organizations/scholarships/list-booster`;
export const getAddCounselorOrganizationsScholarshipsListBooster = (scholarshipVersionIds: string) =>
    `${DOMAIN}/Organizations/scholarships/add-list-booster?scholarshipVersionIds=${scholarshipVersionIds}`;
export const getCounselorScholarshipApplicantsURL = (scholarshipVersionId: number) =>
    `${DOMAIN}/Scholarships/${scholarshipVersionId}/applicants`;
export const getCounselorScholarshipApplicationDetailsURL = (scholarshipVersionId: number, studentId: number) =>
    `${DOMAIN}/Application/application-detail?scholarshipVersionId=${scholarshipVersionId}&studentId=${studentId}`;
export const getCounselorOrganizationDetailsURL = (organizationId: number) =>
    `${DOMAIN}/Organizations/${organizationId}`;
export const getCounselorOrganizationBrandingDetailsURL = `${DOMAIN}/Organizations/Branding`;
export const getCounselorOrganizationAddressDetailsURL = `${DOMAIN}/Organizations/Address`;
export const getCounselorOrganizationBrandingLogoDetailsURL = (organizationId: number, attachmentTypeIds: string) =>
    `${DOMAIN}/Organizations/BrandingLogo?organizationId=${organizationId}&attachmentTypeIds=${attachmentTypeIds}`;
export const getCounselorResourceDetailsURL = `${DOMAIN}/Organizations/college-scholarships`;
export const getCounselorBrandingURL = `${DOMAIN}/Organizations/Branding`;
export const getCounselorCollegesAddURL = (collegeIds: number[]) =>
    `${DOMAIN}/Counselors/colleges/add?collegeIds=${collegeIds}`;
export const getCounselorCollegesURL = () => `${DOMAIN}/Counselors/colleges`;
export const getCounselorSearchCollegesURL = () => `${DOMAIN}/Colleges`;
export const getCounselorSuggestedCollegeDeleteURL = (collegeIds: number[]) =>
    `${DOMAIN}/Counselors/colleges/delete?ids=${collegeIds}`;
export const getCounselorStudentReportURL = () => `${DOMAIN}/Counselors/Organization/college-stat`;

/*contact us url*/
export const getContactUsURL = `${DOMAIN}/ContactUs`;

//sponsors
export const getScholarshipsLogoURL = (id: number) => `${DOMAIN}/Sponsors/${id}/Logo`;
export const getOrganizationSponsorsURL = (organizationId: number) =>
    `${DOMAIN}/Organizations/sponsors?organizationId=${organizationId}`;
export const getUploadSponsorLogoURL = `${DOMAIN}/Sponsors/Logo`;
export const getSponsorLogoClickedURL = (id: number) => `${DOMAIN}/Sponsors/${id}/clicked`;
export const getAdminSponsorsListURL = `${DOMAIN}/dashboard/admin/sponsors`;

//Logout
export const getUserLogoutURL = `${DOMAIN}/Users/logout`;

//Subscribe
export const getUserSubscribeURL = `${DOMAIN}/Users/Subscribe`;

//Deactivate
export const getDeactivateAccountURL = `${DOMAIN}/Users/Deactivate`;

//SoftDelete User
export const getSoftDeleteUserURL = `${DOMAIN}/Users/Deactivate`;
//Admin Prompt User Deleteion
export const getRealDeleteUserURL = `${DOMAIN}/Admin/delete-user`;

//User self account deletion
export const getUserAccountDeletionRequestURL = `${DOMAIN}/Users/Delete`;

//Single Sign On SSO
export const getGoogleSSOAuthenticationURL = () => `${DOMAIN}/ExternalAuth/GoogleAuthentication`;
export const getFacebookSSOAuthenticationURL = (token: string) =>
    `${DOMAIN}/ExternalAuth/FacebookAuthentication?token=${token}`;
export const getAppleSSOAuthenticationURL = () => `${DOMAIN}/ExternalAuth/AppleAuthentication`;


//homepage count url 

export const getHomePageCountURL = () => `${DOMAIN}/Users/HomepageCounts`