export const COLOR_DARKGRAY = '#111827';
export const COLOR_LIGHTGRAY = '#B3B3B3';
export const COLOR_TEXT = '#3B3B3B';
export const COLOR_ORANGE_PRIMARY = '#F7941D';
export const COLOR_BLACK = '#000000';
export const COLOR_DARK = '#0A142F';
export const COLOR_SECONDARY = '#386BF6';
export const COLOR_WHITE = '#FFFFFF';
export const COLOR_BOX = '#373D53';
export const COLOR_GREEN = '#3A9F60';
export const COLOR_RED = '#EA4335';
export const COLOR_LINKTEXT = '#1A1717';
export const COLOR_OTPBG = '#ECECEC';
export const COLOR_GRAY = '#4D4D4D';
export const COLOR_CARD_KEYS = '#324054';
export const COLOR_SUBHEADING = '#737A81';
export const COLOR_CARD_TITLE = '#121416';
export const COLOR_OPTION_BUTTON_BG = '#ECECEC';
export const COLOR_LOCATION = '#545D69';
export const COLOR_GRAY_TEXT = '#666666';
export const COLOR_LIGHTGRAY_BG = '#F6F6F6';
export const COLOR_LIGHTGRAY_BG_PAGE = '#F0F0F0';
export const COLOR_BLACK_SHADE_1 = '#232222';
export const COLOR_BLACK_SHADE_2 = '#CBCBCB';
export const COLOR_BUTTON_HOVER_TEXT_1 = '#0F1B4C';
export const COLOR_GRAY_BACKGROUND = '#203C860D';
export const COLOR_HIGHLIGHT_GRAY = '#9795B5';
export const COLOR_LABEL_TEXT = '#838383';

export const isColor = (strColor: string) => {
    const s = new Option().style;
    s.color = strColor;
    return s.color !== '';
};
